html, body {
    margin: 0;
    padding: 0;
    background-color: #fbf8ff;
}

/*-webkit-touch-callout: none; !* iOS Safari *!*/
/*-webkit-user-select: none; !* Chrome 6.0+, Safari 3.1+, Edge & Opera 15+ *!*/
/*-moz-user-select: none; !* Firefox *!*/
/*-ms-user-select: none; !* IE 10+ and Edge *!*/
/*user-select: none;*/
